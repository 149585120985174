// i18n
import { createI18n } from 'vue-i18n';
import ja from './locales/ja.json';
import en from './locales/en.json';

let locale = localStorage.getItem('lang') || 'jp';

const i18n = createI18n({
  // locale: navigator.language || 'ja',
  locale: locale,
  fallbackLocale: 'ja',
  messages: { ja, en },
  legacy: false
});

export default i18n;
