export const pointsMutations = {
    GET_POINT_DATES(state, payload){
        state.pointDates = payload;
    },
    GET_POINT_MONTHS(state, payload){
        state.pointMonths = payload;
    },
    GET_POINT_YEARS(state, payload){
        state.pointYears = payload;
    }
}