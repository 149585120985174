import axiosConfig from '../../config/axiosConfig';

export const dashBoardsAction = {
    async getDashboardViewList(context, date) {
        let userId = '66398e733f33551dfc31d2f3';
        try {
          const res = await axiosConfig.get(
            `/points-user?shop=${userId}&date=${date}`
          );
    
          context.commit('GET_DASHBOARD_VIEW_LIST', res.data);
        } catch (error) {
          console.log(error);
        }
      },
    
      async getNewUser(context) {
        let userId = '66398e733f33551dfc31d2f3';
        try {
          const res = await axiosConfig.get(`/user-of-shop?shop=${userId}`);
          context.commit('GET_NEW_USER', res.data);
        } catch (error) {
          console.log(error);
        }
      },
}