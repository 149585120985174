import axiosConfig from '../../config/axiosConfig';

export const storeSettingsAction = {
    async updateStoreSetting(context, data) {
        try {
          const formData = new FormData();
          formData.append('name', data.name);
          formData.append('address', data.address);
          formData.append('phone', data.phone);
          if (data.avatar !== '') {
            formData.append('avatar', data.avatar);
          } else {
            formData.append('deleteAvatar', 1);
          }
          const res = await axiosConfig.put(`/shop/${data.id}`, formData);
          const shopSelected = res.data;
          const convertData = {
            store_id: shopSelected?.id,
            store_name: shopSelected?.name,
            store_avatar: shopSelected?.avatar,
            store_address: shopSelected?.address,
            store_phone: shopSelected?.phone
          };
          if (res.status === 200 || res.status === 201) {
            localStorage.setItem('selectedShop', JSON.stringify(convertData));
            context.commit('UPDATE_STORE_SETTING', convertData);
          } else {
            context.commit('UPDATE_SHOP_INFO_ERROR');
          }
        } catch (error) {
          throw new Error(error);
        } finally {
          context.commit('UPDATE_LOADING', false);
        }
      },
}