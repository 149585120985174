
export const uisAction = {
    setLoading(context, status) {
        context.commit('UPDATE_LOADING', status);
    },
    setStatusCollapseSidebar(context, status) {
        context.commit('UPDATE_STATUS_COLLAPSE_SIDEBAR', status);
    },
    setStatusHideSidebar(context, status) {
        context.commit('UPDATE_STATUS_HIDE_SIDEBAR', status);
    },
    setSidebarFocus(context, index = 1) {
        localStorage.setItem('sidebarFocus', index);
        context.commit('UPDATE_SIDEBAR_FOCUS', index);
    },
}