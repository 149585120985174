import axiosConfig from '../../config/axiosConfig';

export const storesAction = {
    async getShopList(context) {
        try {
            const res = await axiosConfig.get('/shop');
            context.commit('GET_SHOP_LIST', res.data);
        } catch (error) {
            throw new Error(error);
        }
    },

    async setSelectedShop(context, data) {
        localStorage.setItem('selectedShop', JSON.stringify(data));
        context.commit('SET_SELECTED_SHOP', data);
    },

    async registerStore(context, data) {
        try {
            const res = await axiosConfig.post('/shop', data.formData);
            console.log(res);
            ((res.status === 200 || res.status === 201) && !res.data.message)
                ? context.commit('REGISTER_SHOP', {
                    onRegisterSuccess: data.onRegisterSuccess
                })
                : context.commit('REGISTER_SHOP_ERROR',{
                    onRegisterError: data.onRegisterError
                });
        } catch (error) {
            throw new Error(error);
        }
    },

    async getAreaList(context) {
        try {
            const res = await axiosConfig.get(`/location`);
            context.commit('GET_AREA_LIST', res.data);
        } catch (error) {
            throw new Error(error);
        }
    }
}