import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../components/views/RegisterStore.vue')
  },
  {
    path: '/register-store',
    name: 'RegisterStore',
    component: () => import('../components/views/RegisterStore.vue')
  },
  {
    path: '/register-store/article',
    name: 'RegisterStore',
    component: () => import('../components/views/Article.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash'
});

export default router;
