import axiosConfig from '../../config/axiosConfig';

export const userPointsAction = {
    async getUserPoint(context, data) {
        try {
          const res = await axiosConfig.get(
            `/gift-points?userReceive=${data.userId}&type=${data.type}&shop=${data.shopId}&date=${data.date}`
          );
          if (res.status === 200) {
            if (data.type == 'month') {
              context.commit('GET_USER_POINT_BY_MONTH_SUCCESS', res.data);
            } else if (data.type == 'year') {
              context.commit('GET_USER_POINT_BY_YEAR_SUCCESS', res.data);
            }
          }
        } catch (error) {
          // throw new Error(error);
          console.log('Get user point error: ', error);
        }
      },
      async getUserPointDetail(context, data) {
        try {
          const res = await axiosConfig.get(
            `/gift-points/detail?type=${data.type}&shop=${data.shop}&date=${
              data.date
            }&type_get=${data.type_get}&gift=${data.gift ? data.gift : ''}`
          );
          if (res.status === 200) {
            if (data.type_get == 'gift') {
              context.commit('GET_USER_POINT_DETAIL_SUCCESS', res.data);
            } else {
              context.commit('GET_USER_POINT_DETAIL_BY_CATEGORY_SUCCESS', res.data);
            }
          }
        } catch (error) {
          console.log('Get user gift point detail failed: ', error);
        }
      },
      async getGiftDetail(context, data) {
        try {
          const res = await axiosConfig.get(`/gift-name/${data}`);
          if (res.status === 200) {
            context.commit('GET_GIFT_DETAIL_SUCCESS', res.data);
          }
        } catch (error) {
          console.log('Get gift detail failed: ', error);
        }
      },
}