import { showAlert } from "../../helpers";

export const storesMutations = {
    GET_SHOP_LIST(state, payload) {
        state.shopList = payload;
    },

    SET_SELECTED_SHOP(state, payload) {
        state.selectedShop = payload;
    },

    REGISTER_SHOP(state, payload) {
        payload.onRegisterSuccess();
    },

    REGISTER_SHOP_ERROR(state, payload) {
        payload.onRegisterError();
        showAlert(
            'error',
            localStorage.getItem('lang') === 'en'
                ? 'Sign up false, email already registered'
                : 'サインアップは間違っています。メールアドレスはすでに登録されています',
            'top-center'
        );
    },

    GET_AREA_LIST(state, payload) {
        state.areaList = payload;
    },
}