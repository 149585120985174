import axiosConfig from '../../config/axiosConfig';

import CryptoJS from 'crypto-js';
import VueCookies from 'vue-cookies';
import { ENCODE_SECRET_KEY } from '../../constants';


export const authActions = {
  async login(context, data) {
    try {
      const res = await axiosConfig.post('/auth/login', data);
      // console.log("res: ", res);
      // VueCookies.set('access_token', encryptData(res.data.access_token), '30d');
      if (res?.status != 401) {
        VueCookies.set(
          'access_token',
          CryptoJS.AES.encrypt(
            res.data.access_token,
            ENCODE_SECRET_KEY
          ).toString(),
          data.rememberMe ? '30d' : '1d'
        );
        context.commit('LOGIN', res.data);
        if (res?.data?.access_token) {
          setTimeout(() => window.location.reload(), 1000);
        }
      } else {
        console.log('Unauthorized');
        context.commit('UNAUTHORIZED_USER_MODAL');
      }
    } catch (errors) {
      console.log(errors.message);
    } finally {
      context.commit('UPDATE_LOADING', false);
    }
  }
};
