export const uisMutation = {
  UPDATE_STATUS_COLLAPSE_SIDEBAR(state, status) {
    state.collapseSidebar = status || !state.collapseSidebar;
  },

  UPDATE_STATUS_HIDE_SIDEBAR(state, status) {
    state.hideSidebar = status || !state.hideSidebar;
  },

  UPDATE_SIDEBAR_FOCUS(state, index) {
    state.sidebarFocus = index;
  },
  UPDATE_LOADING(state, status) {
    state.loading = status;
  },
}