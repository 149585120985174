<template>
  <div class="wrap-two">
    <keep-alive :include="cachedComponents">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const MAX_WIDTH_COLLAPSE_SIDEBAR = 1000;
const MAX_WIDTH_HIDE_SIDEBAR = 500;
const statusCollapse = computed(() => store.state.collapseSidebar);
const statusHide = computed(() => store.state.hideSidebar);
const selectedShop = computed(() => store.state.selectedShop);

const updateWindowWidth = () => {
  const screenW = window.innerWidth;
  if (screenW < MAX_WIDTH_HIDE_SIDEBAR) {
    if (statusCollapse.value === false) {
      handleUpdateStatusCollapseSideBar(true);
      localStorage.setItem("responsive_sidebar", "true");
    }
    if (
      statusHide.value === false &&
      localStorage.getItem("hide_sidebar_action_open") === "false"
    ) {
      handleUpdateStatusHideSideBar(true);
    }
  } else {
    if (statusHide.value === true) {
      handleUpdateStatusHideSideBar(false);
    }
    localStorage.setItem("hide_sidebar_action_open", "false");
    if (screenW < MAX_WIDTH_COLLAPSE_SIDEBAR && statusCollapse.value === false) {
      handleUpdateStatusCollapseSideBar(true);
      localStorage.setItem("responsive_sidebar", "true");
    } else if (
      screenW >= MAX_WIDTH_COLLAPSE_SIDEBAR &&
      localStorage.getItem("responsive_sidebar") === "true"
    ) {
      handleUpdateStatusCollapseSideBar(false);
      localStorage.setItem("responsive_sidebar", "false");
    }
  }
};

const handleUpdateStatusCollapseSideBar = (status) => {
  store.dispatch("setStatusCollapseSidebar", status);
};

const handleUpdateStatusHideSideBar = (status) => {
  store.dispatch("setStatusHideSidebar", status);
};

onMounted(() => {
  updateWindowWidth();
  window.addEventListener("resize", updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>
<style>
@import "./assets/css/views/_main.scss";
</style>
