import { authsMutation } from './auth';
import { dashBoardsMutation } from './dashboard';
import { pointsMutations } from './point';
import { pricingsMutation } from './pricing';
import { storesMutations } from './store';
import { storeSettingsMutation } from './store_setting';
import { uisMutation } from './ui';
import { userPointsMutation } from './user_point';
import { usersMutation } from './users';
export default { 
    ...usersMutation,
    ...storesMutations, 
    ...pointsMutations,
    ...dashBoardsMutation,
    ...authsMutation, 
    ...pricingsMutation,
    ...storeSettingsMutation,
    ...uisMutation,
    ...userPointsMutation,
};
