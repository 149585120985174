export const userPointsMutation = {
  GET_USER_POINT_BY_MONTH_SUCCESS(state, payload) {
    state.userGiftPointByMonth = payload;
  },
  GET_USER_POINT_BY_YEAR_SUCCESS(state, payload) {
    state.userGiftPointByYear = payload;
  },
  GET_USER_POINT_DETAIL_SUCCESS(state, payload) {
    state.userGiftPointDetail = payload;
  },
  GET_USER_POINT_DETAIL_BY_CATEGORY_SUCCESS(state, payload) {
    state.userGiftPointDetailByCategory = payload;
  },
  GET_GIFT_DETAIL_SUCCESS(state, payload) {
    state.giftDetail = payload;
  },
}