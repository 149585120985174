import { showAlert } from "../../helpers";

export const pricingsMutation = {
    GET_PRICING_LIST(state, payload) {
        state.pricingList = payload;
      },
    
      UPGRATE_PRICING(state, payload) {
        state.totalUserCanCreate += payload;
        showAlert(
          'success',
          localStorage.getItem('lang') === 'en'
            ? 'User added successfully'
            : 'ユーザーの追加に成功しました。',
          'top-center'
        );
      },
    
      UPGRATE_PRICING_ERROR() {
        showAlert(
          'success',
          localStorage.getItem('lang') === 'en'
            ? 'User added failed'
            : 'ユーザーの追加に失敗しました。',
          'top-center'
        );
      },
}