import { dashboardsState } from './dashboard';
import { pointsState } from './point';
import { storesState } from './store';
import { uisState } from './ui';
import { userPointsState } from './user_point';
import { usersState } from './users';

export default { 
    ...usersState,
    ...storesState, 
    ...pointsState,
    ...userPointsState,
    ...dashboardsState,
    ...uisState
};
