import axiosConfig from '../../config/axiosConfig';

export const pricingsAction = {
    async getPricingList(context) {
        try {
          const res = await axiosConfig.get('/packages-price');
          context.commit('GET_PRICING_LIST', res.data);
        } catch (error) {
          throw new Error(error);
        }
      },
    
      async upgradePricing(context, pricingInfo) {
        try {
          const res = await axiosConfig.post('/purchased-packages', pricingInfo);
          res.status === 200 || res.status === 201
            ? context.commit('UPGRATE_PRICING', pricingInfo.number_user)
            : context.commit('UPGRATE_PRICING_ERROR');
        } catch (error) {
          throw new Error(error);
        }
      },
}