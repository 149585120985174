import { showAlert } from "../../helpers";

export const usersMutation = {

  GET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },

  GET_USER_LIST(state, payload) {
    state.userList = payload;
    state.totalUsers = state.userList.length;
  },

  SAVE_USER_INFO(state, payload) {
    state.userList.push(payload);
    state.totalUsers = state.totalUsers + 1;
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'User saved successfully'
        : 'ユーザーの保存に成功しました。',
      'top-center'
    );
  },

  SAVE_USER_INFO_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'User saved failed, user id already exist'
        : 'ユーザーの保存に失敗しました。ユーザーIDが存在しました。 ',
      'top-center'
    );
  },

  GET_USER_DETAILS(state, payload) {
    state.userDetails = payload;
  },

  UPDATE_USER_INFO(state, payload) {
    state.userList = state.userList?.map((item) => {
      if (item?._id === payload?._id) {
        return { ...item, ...payload };
      }
      return item;
    });
    state.userDetails = payload;
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'User updated successfully'
        : 'ユーザーの更新に成功しました。',
      'top-center'
    );
  },

  UPDATE_USER_INFO_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'User updated failed, user id already exist'
        : 'ユーザーの更新に失敗しました。ユーザーIDが存在しました。 ',
      'top-center'
    );
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted avatar successfully'
        : 'アバターの削除に成功しました。',
      'top-center'
    );
  },

  DELETE_PERMANENTLY_USERS(state, payload) {
    state.userDeletedList = state.userDeletedList.filter(user => {
      return !payload.some(item => item === user._id);
    });
    showAlert("success", localStorage.getItem('lang') === 'en' ? "User has been permanently deleted" : "ユーザーは完全に削除されました", "top-center")
  },

  DELETE_PERMANENTLY_USERS_ERROR(){
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Delete history failed'
        : '履歴の削除に失敗しました',
      'top-center'
    );
  },

  USER_CHANGE_PASSWORD_SUCCESS() {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Password change successfully'
        : 'パスワード変更に成功しました。',
      'top-center'
    );
  },

  USER_CHANGE_PASSWORD_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Password change failed'
        : 'パスワード変更に失敗しました。',
      'top-center'
    );
  },

  DELETE_USER_AVATAR(state, payload) {
    state.userList = state.userList?.map((item) => {
      if (item?._id === payload?._id) {
        return { ...item, avatar: null };
      }
      return item;
    });
    state.userDetails = payload;
  },

  DELETE_USER_AVATAR_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Failed to delete avatar, try again later'
        : 'アバターの削除に失敗しました。後で再度お試してください。 ',
      'top-center'
    );
  },

  DELETE_USER(state, payload) {
    state.userList = state.userList?.filter(
      (item) => item._id !== payload?._id
    );
    state.userDetails = {};
    state.totalUsers = state.totalUsers - 1;
    setTimeout(() => {
      window.location.replace('/user');
    }, 1000);
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted account successfully'
        : 'アカウントの削除に成功しました。',
      'top-center'
    );
  },

  DELETE_USER_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted account failed'
        : 'アカウントの削除に失敗しました。',
      'top-center'
    );
  },

  GET_TOTAL_SLOT_USERS(state, payload) {
    state.totalUserCanCreate = payload;
  },

  GET_USER_DELETED(state, payload){
    state.userDeletedList = payload;
  }
};
