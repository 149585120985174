import { authActions } from './auth';
import { dashBoardsAction } from './dashboard';
import { pointsAction } from './point';
import { pricingsAction } from './pricing';
import { storesAction } from './store';
import { storeSettingsAction } from './store_setting';
import { uisAction } from './ui';
import { userPointsAction } from './user_point';
import { usersAction } from './users';

export default {
  ...usersAction,
  ...authActions,
  ...storesAction,
  ...pointsAction,
  ...dashBoardsAction,
  ...pricingsAction,
  ...storeSettingsAction,
  ...uisAction,
  ...userPointsAction
};
