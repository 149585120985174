import { showAlert } from "../../helpers";

export const storeSettingsMutation = {
    UPDATE_STORE_SETTING(state, payload) {
        state.selectedShop = payload;
        showAlert(
          'success',
          localStorage.getItem('lang') === 'en'
            ? 'Store setting has been updated'
            : '更新しました',
          'top-center'
        );
      },
      UPDATE_SHOP_INFO_ERROR() {
        showAlert(
          'error',
          localStorage.getItem('lang') === 'en'
            ? 'Store setting update failed'
            : 'アップデートを失敗しました\n再設定してください',
          'top-center'
        );
      },
}